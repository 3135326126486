<template>
  <div>
    <button
      class="transition rounded hover:bg-grey-lighter inline-flex p-2"
      @click="(event) => isOpen ? close(event) : open(event)"
    >
      <icon
        class="w-3 h-3"
        icon="ellipsis"
      />
    </button>
    <div
      v-if="isOpen"
      class="context-actions__menu absolute pin-r bg-grey-f6 z-10 border border-grey-aa shadow-lg text-left overflow-hidden flex flex-col"
    >
      <button
        v-for="(action, index) in actions"
        :key="index"
        class="px-3 py-1 w-full text-left transition hover:bg-grey-lighter whitespace-no-wrap"
        @click="handleActionClick(action.action, $event)"
      >
        {{ action.name }}
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '../Icon'

export default {
  name: 'ContextActions',
  components: { Icon },
  props: {
    actions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    handleActionClick(action, event) {
      event.stopPropagation()
      action()
    },
    open(event) {
      event.stopPropagation()
      this.isOpen = true
      window.requestAnimationFrame(() => {
        document.addEventListener('click', this.close)
      })
    },
    close(e) {
      e.stopPropagation()
      if (!e.target.matches('.context-actions__menu')) {
        this.isOpen = false
        document.removeEventListener('click', this.close)
      }
    }
  }
}
</script>

<style scoped>
  .context-actions__menu {
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.33), inset 0px 0px 2px rgba(255, 255, 255, 0.5);
  }

  .icon {
    min-width: 12px;
    min-height: 12px;
  }
</style>
