<style lang="scss">

.table {
  overflow-y: visible !important;
}

.mobile-filter {
  z-index: 100;
}

.invoice-header {
  .select-container {
    z-index: 100;
  }

  @media (min-width: 1024px) {
    position: absolute;
    left: 0;
    top: 5px;
  }

  .select-container {
    @media (min-width: 1024px) {
      position: absolute;
      top: -21px;
      right: 245px;
      width: 110px;
    }
  }
}

.export-moneybird {
  position: absolute;
  top: -52px;
  right: 15px;
  z-index: 100;

  @media (min-width: 1024px) {
    right: 510px;
    top: -21px;
  }
}

.create-invoice {
  position: absolute;
  top: -52px;
  right: 170px;
  z-index: 100;

  @media (min-width: 1024px) {
    right: 360px;
    top: -21px;
  }
}

.mobile-filter {
  position: absolute;
  top: 47px;
  left: 0;
}
</style>

<template>
  <div class="px-4 mt-8 pb-32 mx-auto max-w-2xl">
    <vue-headful
      title="Invoices | Octoa"
    />

    <invoice-email-slide-in
      :mode.sync="emailEditMode"
      :show.sync="showEmailSlide"
      :email.sync="email"
      :invoice.sync="invoice"
      @close="hideEmailSlideIn"
      @saved="savedEmailSlideIn"
    />

    <modal
      name="paymentModal"
      class="modal overflow-visible"
      width="90%"
      height="280"
      :max-width="600"
      :adaptive="true"
    >
      <div class="flex justify-between">
        <div class="title">
          Add payment
        </div>
        <div
          class="close "
          @click="$modal.hide('paymentModal')"
        />
      </div>

      <div class="flex mt-5 items-center">
        <div class="w-4/12 md:w-32">
          <div class="pr-2">
            Payment date:
          </div>
        </div>
        <div class="w-8/12 md:w-4/12">
          <v-date-picker
            class="datepicker"
            v-model="addPayment.date"
            :popover="{ visibility: 'click' }"
            :input-props="{
              placeholder: &quot;Date of payment&quot;,
            }"
            :masks="{L: 'WWW, MMM D, YYYY'}"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                type="text"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="flex flex-wrap mt-3 items-center">
        <div class="w-4/12 md:w-32">
          <div class="pr-2">
            Amount ({{ currency.sign }})
          </div>
        </div>
        <div class="w-8/12 md:w-4/12">
          <vue-numeric
            v-model="addPayment.amount"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            :currency="currency.sign"
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
            :min="0"
            :max="addPayment.remainingDueTotal"
          />
        </div>
        <div class="w-full md:w-auto mt-5 md:mt-0 md:ml-4">
          Remaining amount:
          <vue-numeric
            :value="addPayment.remainingDueTotal"
            :currency-symbol-position="(currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            :currency="currency.sign"
            read-only
            :minus="false"
            :decimal-separator="currency.decimal_separator"
            :thousand-separator="currency.thousand_separator"
            :min="0"
          />
        </div>
      </div>

      <div class="flex justify-center md:justify-end flex-wrap mt-8 items-center">
        <div class="w-1/2 text-center md:text-right md:w-3/12">
          <v-button
            class="green-btn"
            @click="recordPayment"
          >
            Add payment
          </v-button>
        </div>
        <div class="w-1/2 text-center md:text-right md:w-1/12 md:ml-5">
          <div
            class="underline text-green cursor-pointer"
            @click="$modal.hide('paymentModal')"
          >
            Cancel
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="exportModal"
      class="modal overflow-visible"
      width="90%"
      height="320"
      :max-width="450"
      :adaptive="true"
      :click-to-close="false"
    >
      <div
        v-if="!isExporting"
        class="flex justify-between"
      >
        <div class="title">
          Export Invoices to Moneybird
        </div>
        <div
          class="close "
          @click="$modal.hide('exportModal')"
        />
      </div>
      <div
        v-if="isExporting"
        class="text-center mt-20 pt-2"
      >
        <img
          src="@/assets/img/icons/loader.svg"
          width="45"
        >
        <div class="mt-1 leading-loosen">
          Hang on, we're transfering your invoices to Moneybird.
        </div>
      </div>
      <div v-if="!isExporting">
        <p class="leading-loosen">
          Choose a date range (based on the issued date) of the invoices that has a invoice number you would like to export.
        </p>
        <div class="flex mt-5 items-center">
          <div class="w-2/12">
            <div class="pr-4">
              From:
            </div>
          </div>
          <div class="w-5/12 ">
            <v-date-picker
              class="datepicker"
              v-model="exportFromDate"
              :popover="{ visibility: 'click' }"
              :input-props="{
                placeholder: &quot;Date&quot;,
              }"
              :masks="{L: 'WWW, MMM D, YYYY'}"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
        </div>
        <div class="flex mt-5 items-center">
          <div class="w-2/12">
            <div class="pr-4">
              To:
            </div>
          </div>
          <div class="w-5/12 ">
            <v-date-picker
              class="datepicker"
              v-model="exportToDate"
              :popover="{ visibility: 'click' }"
              :input-props="{
                placeholder: &quot;Date&quot;,
              }"
              :masks="{L: 'WWW, MMM D, YYYY'}"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
        </div>

        <div class="flex justify-center md:justify-end flex-wrap mt-5 items-center">
          <div class="w-1/2 text-center md:text-right">
            <v-button
              class="green-btn"
              @click="exportToMoneybird"
            >
              Export
            </v-button>
          </div>
          <div class="w-1/2 text-center md:text-right md:w-2/12">
            <div
              class="underline text-green cursor-pointer"
              @click="$modal.hide('exportModal')"
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </modal>

    <div class="invoice-header flex items-center w-full">
      <div class="w-full lg:w-1/3">
        <h1 class="text-black text-3xl lg:pl-4">
          Invoices
        </h1>
      </div>
      <div class="w-2/3 hidden lg:block text-right mr-2 ">
        <router-link
          class="green-btn create-invoice"
          :to="{path: '/invoice/choose'}"
        >
            <span>Create Invoice</span>
        </router-link>
        <div
          v-if="isMoneybirdConnected"
          class="export-moneybird"
          @click="showExportModal"
        >
          <div class="green-btn">
            Export to Moneybird
          </div>
        </div>
        <div class="select-container bg-white ">
          <select
            v-model="viewFilter"
            @change="updateFilter"
          >
            <option value="all">
              All ({{ allCount }})
            </option>
            <option value="draft">
              Draft ({{ draftCount }})
            </option>
            <option value="sent">
              Sent ({{ sentCount }})
            </option>
            <option value="paid">
              Paid ({{ paidCount }})
            </option>
            <option value="due">
              Late ({{ dueCount }})
            </option>
            <option value="credit">
              Credit ({{ creditCount }})
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="mobile-filter flex px-4 items-center w-full">
      <div class="create-invoice"
           :style="!isMoneybirdConnected ? 'right:15px' : ''">
      <router-link
          class="green-btn block lg:hidden"
          :to="{path: '/invoice/choose'}"
        >
          <span>Create Invoice</span>
        </router-link>
      </div>
      <div class="export-moneybird block lg:hidden">
        <div
          v-if="isMoneybirdConnected"
          class="green-btn"
          @click="showExportModal"
        >
          Exp. Moneybird
        </div>
      </div>
      <div class="w-1/2 block lg:hidden ">
        <div class="select-container bg-white ">
          <select
            v-model="viewFilter"
            @change="updateFilter"
          >
            <option value="all">
              All ({{ allCount }})
            </option>
            <option value="draft">
              Draft ({{ draftCount }})
            </option>
            <option value="sent">
              Sent ({{ sentCount }})
            </option>
            <option value="paid">
              Paid ({{ paidCount }})
            </option>
            <option value="due">
              Late ({{ dueCount }})
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="mb-12 block lg:hidden">
      <v-server-table
        ref="mobileDatatable"
        class="invoice mobile-project-overview"
        :columns="mobileColumns"
        :options="mobileOptions"
      >
        <div
          slot="issued_at"
          slot-scope="props"
        >
          <div v-if="props.row.state != 'sent'">
            <div

              class="item-invoice-state mt-0 inline-block"
              :class="{'draft' : props.row.state == 'draft', 'sent' : props.row.state == 'sent', 'paid' : props.row.state == 'paid'}"
            >
              {{ props.row.state }}
            </div>
            <div class="mt-1">
              &nbsp;
            </div>
          </div>
          <div
            v-else
          >

            <div v-if="today < props.row.valid_until && props.row.total >= 0">
              <span

                class="item-invoice-state mt-0 large inline-block sent"
              >
                {{ props.row.state }}
              </span>
              <div class=" mt-1">
                &nbsp;
              </div>
            </div>
            <div v-if="today > props.row.valid_until && props.row.total >= 0">
              <span
                class="item-invoice-state mt-0 large inline-block overdue"
              >
                LATE
              </span><br>
              <div class="item-invoice-due-days mt-1">
                {{ props.row.valid_until | timeFromNowSimple }} ago
              </div>
            </div>
            <div v-if="props.row.total < 0">
              <span
                class="item-invoice-state mt-0 large inline-block overdue"
              >
                CREDIT
              </span>
            </div>
          </div>
          <div class="mt-6 pt-px text-xs text-grey-semi-light">
            Issued date
          </div>
          <div style="height:34px;">
            {{ props.row.issued_at | humanDateNoConvert }}
          </div>

          <div class="mt-5 text-xs text-grey-semi-light">
            Amount
          </div>
          <vue-numeric
            :currency-symbol-position="(props.row.currency.sign_after_price) ? 'suffix' : 'prefix'"
            :precision="2"
            :currency="props.row.currency.sign"
            read-only
            :minus="false"
            :decimal-separator="props.row.currency.decimal_separator"
            :thousand-separator="props.row.currency.thousand_separator"
            :min="0"
            :value="props.row.due_total"
          />
        </div>
        <div
          slot="invoice_number"
          slot-scope="props"
        >
          <div class="text-xs text-grey-semi-light">
            Number
          </div>
          <div v-if="props.row.invoice_number">
            {{ props.row.invoice_number }}
          </div>
          <div v-else>
            -
          </div>
          <div class="mt-8 text-xs text-grey-semi-light">
            Client
          </div>
          <div style="height:34px;">
            <router-link
              v-if="props.row.project"
              :to="`/projects/${props.row.project.id}`"
              class="green"
            >
              {{ props.row.project.contact.first_name }} {{ props.row.project.contact.last_name }}
            </router-link>
          </div>
          <div class="mt-8">
            <span
              v-show="props.row.state == 'sent' && props.row.due_total > 0"
              v-tooltip="{ ...tooltip, content: 'Add payment' }"
              class="cursor-pointer mr-4"
              @click="showAddPayment(props.row)"
            >
              <svg
                width="21px"
                height="15px"
                viewBox="0 0 21 15"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  id="04---Desktop-Pages"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="8.1---New-Proposal"
                    transform="translate(-1061.000000, -26.000000)"
                    fill="#1b41bc"
                    fill-rule="nonzero"
                  >
                    <g
                      id="accounting-bills"
                      transform="translate(1061.000000, 26.000000)"
                    >
                      <path
                        id="Shape"
                        d="M17.8282186,11.193 L17.8282186,1.71325 C17.8316305,1.46539804 17.736521,1.22630741 17.5637244,1.04858787 C17.3909279,0.870868338 17.1546026,0.769081626 16.90675,0.765625 L1.2495,0.765625 C1.00164735,0.769081626 0.765322117,0.870868338 0.592525561,1.04858787 C0.419729005,1.22630741 0.324619483,1.46539804 0.328031423,1.71325 L0.328031423,11.193 C0.324619483,11.440852 0.419729005,11.6799426 0.592525561,11.8576621 C0.765322117,12.0353817 1.00164735,12.1371684 1.2495,12.140625 L16.90675,12.140625 C17.1546026,12.1371684 17.3909279,12.0353817 17.5637244,11.8576621 C17.736521,11.6799426 17.8316305,11.440852 17.8282186,11.193 Z M9.078125,9.078125 C7.62837753,9.078125 6.453125,7.90287247 6.453125,6.453125 C6.453125,5.00337753 7.62837753,3.828125 9.078125,3.828125 C10.5278725,3.828125 11.703125,5.00337753 11.703125,6.453125 C11.703125,7.14931829 11.4265633,7.81699731 10.9342803,8.3092803 C10.4419973,8.80156329 9.77431829,9.078125 9.078125,9.078125 Z"
                      />
                      <path
                        id="Path"
                        d="M20.015625,3.171875 C19.6531881,3.171875 19.359375,3.46568813 19.359375,3.828125 L19.359375,13.453125 C19.359375,13.5739373 19.2614373,13.671875 19.140625,13.671875 L3.390625,13.671875 C3.02818813,13.671875 2.734375,13.9656881 2.734375,14.328125 C2.734375,14.6905619 3.02818813,14.984375 3.390625,14.984375 L19.140625,14.984375 C19.9859112,14.9834106 20.6709106,14.2984112 20.671875,13.453125 L20.671875,3.828125 C20.671875,3.46568813 20.3780619,3.171875 20.015625,3.171875 Z"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span
              v-show="props.row.invoice_number > 0"
              class="cursor-pointer mr-4"
              @click="savePdf(props.row)"
            >
              <img
                src="@/assets/img/icons/ico-download.svg"
                width="18"
              >
            </span>
            <router-link
              :to="`/invoices/preview/${props.row.id}`"
            >
              <img
                src="@/assets/img/icons/ico-view.svg"
                width="20"
              >
            </router-link>
          </div>
        </div>
      </v-server-table>
    </div>

    <div class="hidden lg:block">
      <v-server-table
        ref="datatable"
        class="invoice-overview"
        :columns="columns"
        :options="options"
      >
        <div
          slot="state"
          slot-scope="props"
          class="capitalize"
        >
          <div
            v-if="props.row.state != 'sent' && props.row.total > 0"
            class="item-invoice-state mt-0 inline-block"
            :class="{'draft' : props.row.state == 'draft', 'sent' : props.row.state == 'sent', 'paid' : props.row.state == 'paid'}"
          >
            {{ props.row.state }}
          </div>
          <div
            v-else
          >
            <span
              v-if="today < props.row.valid_until && props.row.total >= 0"
              class="item-invoice-state mt-0 large inline-block sent"
            >
              {{ props.row.state }}
            </span>
            <div v-if="today > props.row.valid_until && props.row.total >= 0">
              <span
                class="item-invoice-state mt-0 large inline-block overdue"
              >
                LATE
              </span>
              <span class="item-invoice-due-days ml-3"> {{ props.row.valid_until | timeFromNowSimple }} ago </span>
            </div>
            <div v-if="props.row.total < 0">
              <span
                class="item-invoice-state mt-0 large inline-block overdue"
              >
                CREDIT
              </span>
            </div>
          </div>
        </div>
        <div
          slot="issued_at"
          slot-scope="props"
        >
          <span v-if="props.row.issued_at">
            {{ props.row.issued_at | humanDateNoConvert }}
          </span>
          <span v-else>
            -
          </span>
        </div>

          <div
            slot="project"
            slot-scope="props"
          >
            <div v-if="props.row.project">
              <router-link
                :to="`/projects/${props.row.project.id}`"
                class="green"
              >
                {{ props.row.project.contact.first_name }} {{ props.row.project.contact.last_name }}
              </router-link>
            </div>
            <div v-if="props.row.contact">
              <router-link
                :to="`/contacts/${props.row.contact.id}`"
                class="green"
              >
                {{ props.row.contact.first_name }} {{ props.row.contact.last_name }}
              </router-link>
            </div>
          </div>

        <div
          slot="total"
          slot-scope="props"
        >
          <div class="flex justify-between items-end">
            <div class="w-1/2">
              <vue-numeric
                :currency-symbol-position="(props.row.currency.sign_after_price) ? 'suffix' : 'prefix'"
                :precision="2"
                :currency="props.row.currency.sign"
                read-only
                :minus="false"
                :decimal-separator="props.row.currency.decimal_separator"
                :thousand-separator="props.row.currency.thousand_separator"
                :min="0"
                :value="props.row.due_total"
              />
            </div>
            <div class="w-1/2 text-right flex items-center">
              <div class="ml-auto"></div>
              <span
                v-if="props.row.state != 'paid'"
                class="cursor-pointer mr-4 relative inline-block"
                @click="sendMail(props.row)"
              >
                <img
                  v-tooltip="{ ...tooltip, content: 'Email', boundariesElement: 'window' }"
                  src="@/assets/img/icons/email-blue.svg"
                  width="18"
                  v-if="props.row.state == 'draft'"
                >
                <img
                  v-tooltip="{ ...tooltip, content: 'Send again', boundariesElement: 'window' }"
                  src="@/assets/img/icons/email-blue.svg"
                  width="18"
                  v-if="props.row.state == 'sent'"
                >
              </span>
              <span
                v-show="props.row.state == 'sent' && props.row.due_total > 0"
                class="cursor-pointer mr-4"
                @click="showAddPayment(props.row)"
              >
                <svg
                  v-tooltip="{ ...tooltip, content: 'Add payment', boundariesElement: 'window' }"
                  class=" relative inline-block"
                  width="21px"
                  height="15px"
                  viewBox="0 0 21 15"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="04---Desktop-Pages"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="8.1---New-Proposal"
                      transform="translate(-1061.000000, -26.000000)"
                      fill="#1b41bc"
                      fill-rule="nonzero"
                    >
                      <g
                        id="accounting-bills"
                        transform="translate(1061.000000, 26.000000)"
                      >
                        <path
                          id="Shape"
                          d="M17.8282186,11.193 L17.8282186,1.71325 C17.8316305,1.46539804 17.736521,1.22630741 17.5637244,1.04858787 C17.3909279,0.870868338 17.1546026,0.769081626 16.90675,0.765625 L1.2495,0.765625 C1.00164735,0.769081626 0.765322117,0.870868338 0.592525561,1.04858787 C0.419729005,1.22630741 0.324619483,1.46539804 0.328031423,1.71325 L0.328031423,11.193 C0.324619483,11.440852 0.419729005,11.6799426 0.592525561,11.8576621 C0.765322117,12.0353817 1.00164735,12.1371684 1.2495,12.140625 L16.90675,12.140625 C17.1546026,12.1371684 17.3909279,12.0353817 17.5637244,11.8576621 C17.736521,11.6799426 17.8316305,11.440852 17.8282186,11.193 Z M9.078125,9.078125 C7.62837753,9.078125 6.453125,7.90287247 6.453125,6.453125 C6.453125,5.00337753 7.62837753,3.828125 9.078125,3.828125 C10.5278725,3.828125 11.703125,5.00337753 11.703125,6.453125 C11.703125,7.14931829 11.4265633,7.81699731 10.9342803,8.3092803 C10.4419973,8.80156329 9.77431829,9.078125 9.078125,9.078125 Z"
                        />
                        <path
                          id="Path"
                          d="M20.015625,3.171875 C19.6531881,3.171875 19.359375,3.46568813 19.359375,3.828125 L19.359375,13.453125 C19.359375,13.5739373 19.2614373,13.671875 19.140625,13.671875 L3.390625,13.671875 C3.02818813,13.671875 2.734375,13.9656881 2.734375,14.328125 C2.734375,14.6905619 3.02818813,14.984375 3.390625,14.984375 L19.140625,14.984375 C19.9859112,14.9834106 20.6709106,14.2984112 20.671875,13.453125 L20.671875,3.828125 C20.671875,3.46568813 20.3780619,3.171875 20.015625,3.171875 Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <span
                v-show="props.row.invoice_number > 0"
                class="cursor-pointer mr-4 relative inline-block"
                @click="savePdf(props.row)"
              >
                <img
                  v-tooltip="{ ...tooltip, content: 'Download', boundariesElement: 'window' }"
                  src="@/assets/img/icons/ico-download.svg"
                  width="18"
                >
              </span>
              <router-link
                :to="`/invoices/preview/${props.row.id}`"
                target="_blank"
              >
                <img
                  v-tooltip="{ ...tooltip, content: 'View', boundariesElement: 'window' }"
                  src="@/assets/img/icons/ico-view.svg"
                  width="20"
                >
              </router-link>
            </div>
          </div>
        </div>
      </v-server-table>
    </div>
  </div>
</template>

<script>
import {format} from 'date-fns'
import {parseFromTimeZone} from 'date-fns-timezone'
import {logException} from '@/helpers'
import {createCancelSource} from '@/http'
import {mapGetters} from 'vuex'
import {calculateEachTaxClassPrice, calculateDiscountTotal, calculateTaxTotal} from '@/utils/helpers'
import {saveAs} from 'file-saver'
import ContextActions from "@/components/Tasks/ContextActions.vue";
import Icon from "@/components/Icon.vue";
import StatusBadge from "@/components/Files/StatusBadge.vue";
import {deleteWorkflowJob} from "@/api/WorkflowJobs";
import InvoiceEmailSlideIn from './Extension/InvoiceEmailSlideIn'
import config from "@/config";

let cancelSource

export default {
  components: { InvoiceEmailSlideIn },
  data() {
    return {
      loading: true,
      isSaving: false,
      isMoneybirdConnected: false,
      today: null,
      taskModalMode: 'new',
      tasks: [],
      taskIsCompleted: 0,
      taskItemId: null,
      taskName: '',
      taskDueDate: '',
      contact: null,
      attachTaskId: null,
      selectedProject: null,
      filter: 'all',
      search: '',
      allCount: 0,
      draftCount: 0,
      sentCount: 0,
      dueCount: 0,
      paidCount: 0,
      creditCount: 0,
      sortBy: 'date',
      sortType: 'desc',
      timezone: '',
      viewFilter: 'all',
      isPdfLoading: false,
      exportFromDate: null,
      exportToDate: new Date(),
      isExporting: false,
      showEmailSlide: false,
      emailEditMode: 'edit',
      invoice: null,
      email: {
        emailType: '',
        emailId: null,
        isBlankEmail: false,
        autocompleteEmailTagItems: [],
        selectEmailTemplate: '',
        emailTags: [],
        templates: [],
        fromEmailView: '',
        toEmail: '',
        fromEmail: '',
        subjectEmail:'',
        contentEmail: '',
        emailUpdatePending: false,
      },
      currency: {
        sign: '€'
      },
      addPayment: {
        date: null,
        amount: 0,
      },
      tooltip: {
        delay: 0,
        show: false,
        offset: 0,
        trigger: 'hover',
        classes: ['header-tooltip'],
        placement: 'bottom',

      },
      isMobile: false,
      columns: ['state', 'issued_at', 'invoice_number', 'project', 'total'],
      mobileColumns: ['issued_at', 'invoice_number'],
      options: {
        requestAdapter: (data) => {
          data.companyId = this.user.company.id
          data.filter = this.viewFilter
          return data
        },
        async requestFunction(data) {
          const response = await this.$api.get('invoice').overview(data)
          return response.data
        },
        headings: {
          state: 'Status',
          issued_at: 'Issue date',
          invoice_number: 'Number',
          project: 'Client',
          total: 'Due amount',
        },
        orderBy: {column: 'issued_at', ascending: false},
        sortable: ['issued_at', 'invoice_number'],
        descOrderColumns: ['issued_at', 'invoice_number'],
        perPage: 25,
        perPageValues: [25, 50, 75, 100],
        serverMultiSorting: true,
        texts: {
          filterPlaceholder: 'Find an invoice',
          noResults: 'No invoices found',
        },
      },
      mobileOptions: {
        requestAdapter: (data) => {
          data.companyId = this.user.company.id
          data.filter = this.viewFilter
          return data
        },
        async requestFunction(data) {
          const response = await this.$api.get('invoice').overview(data)
          return response.data
        },
        headings: {
          issued_at: 'Issue date',
          invoice_number: 'Number',
        },
        orderBy: {column: 'issued_at', ascending: false},
        sortable: ['issued_at', 'invoice_number'],
        descOrderColumns: ['issued_at', 'invoice_number'],
        perPage: 25,
        perPageValues: [25, 50, 75, 100],
        serverMultiSorting: true,
        texts: {
          filterPlaceholder: 'Search a invoice',
          noResults: 'No invoices found',
        },
      }
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, {passive: true})
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  async mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})

    this.today = format(new Date(), 'YYYY-MM-DD')
    this.timezone = this.user.companySettings.timezone

    try {
      await this.$store.dispatch('company/refreshCompanyDetails')
      const company = this.$store.getters['company/details']
      if (company.moneybirdSettings.access_token) {
        this.isMoneybirdConnected = true
      } else {
        this.isMoneybirdConnected = false
      }
    } catch (e) {
      this.$toasted.global.api_error(e)
    }

    await this.loadMailTemplates()

    await this.getData()
  },
  methods: {
    async sendMail(item) {
      this.email.emailId = item.id
      this.showEmailSlide = true
      this.invoiceId = item.id
      this.invoice = item
      this.contact = item.project ? item.project.contact : item.contact

      this.email.fromEmail = this.user.companySettings.senderEmail
      this.email.fromEmailView = `From: ${this.user.companySettings.senderName} <${this.user.companySettings.senderEmail}>`
      this.email.emailType = 'invoice'

      const draftExists = await this.loadDraft()

      // Only generate default content if no draft exists
      if (!draftExists) {
        this.generateEmailContent()
      }

      return true
    },
    generateEmailContent() {
      this.email.emailTags = []
      this.email.emailTags.push({
        text: this.contact.email,
      })

      const signature = (!this.user.me.email_signature) ? '' : this.user.me.email_signature

      this.email.contentEmail = `<p>Hi ${this.contact.first_name}, </p><p></p><p>I hereby send you my invoice! Please contact me if you have any questions or if things are not clear. I'm glad to help you further. <p><p></p><p>Link to invoice: <a href="@documentLink">@documentLink</a> </p><p>${signature}</p>`
      this.email.emailUpdatePending = true
    },
    async hideEmailSlideIn(){
      this.showEmailSlide = false
    },
    async savedEmailSlideIn(){
      this.showEmailSlide = false
      this.isSendingMail = false

      if(this.user.companySettings.moneybirdSettings.auto_export_invoices){
        try {
          await this.$api.get('invoice').exportMoneybird(this.user.company.id, {invoiceId: this.invoiceId})
          this.$router.push(`/invoices`)

        } catch(e){
          this.$toasted.global.api_error(e)
        }
      } else {
        this.$router.push(`/invoices`)
      }
    },
    async loadMailTemplates(){
      try {
        const { data } = await this.$api.get('templateMail').list(this.user.company.id)
        this.email.templates = []

        data.mails.forEach(template => {
          this.email.templates.push({template})
        })
      } catch(e){
        logException(e)
      }
    },
    async loadDraft() {
      this.email.selectEmailTemplate = ''

      try {
        const { data } = await this.$api.get('mail').loadDraft(`${this.invoiceId}`, 'invoice')

        if (data.result !== 'Empty') {
          this.email.emailTags = []
          data.toEmails.forEach(email => {
            this.email.emailTags.push({
              text: email,
            })
          })

          this.email.fromEmail = data.from
          this.email.fromEmailView = `From: ${this.user.companySettings.senderName} <${this.user.companySettings.senderEmail}>`
          this.email.subjectEmail = data.subject
          this.email.contentEmail = data.content
          this.email.emailUpdatePending = true

          return true // Draft exists and was loaded
        }

        return false // No draft exists
      } catch (e) {
        logException(e)
        return false // Error occurred, treat as no draft
      }
    },
    async deleteAction (action) {
      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete this invoice?',
      })

      if (!canDelete) return

      const res = this.handleErrors(this.$api.delete('invoice').delete(action.id))
      if (res) {
        this.$emit('update')
      }
    },
    showExportModal() {
      this.isExporting = false
      this.$toasted.clear()
      this.$modal.show('exportModal')
    },
    async exportToMoneybird() {
      this.$toasted.clear()
      if (!this.exportFromDate || !this.exportToDate) {
        this.$toasted.global.general_error({
          message: 'Both from and to date should have a valid date.'
        })
        return false
      }

      if (this.exportFromDate > this.exportToDate) {
        this.$toasted.global.general_error({
          message: 'From date cannot be later than To date.'
        })
        return false
      }

      this.isExporting = true

      try {
        const {data} = await this.$api.get('moneybird').init(this.user.company.id)
      } catch (e) {

      }

      try {
        const {data} = await this.$api.get('moneybird').export(this.user.company.id, {fromDate: this.exportFromDate, toDate: this.exportToDate})
        this.isExporting = false

        this.$toasted.global.general_success({
          message: `Total of ${data.exported} invoice(s) exported.`
        })

        this.$modal.hide('exportModal')

      } catch (e) {

        this.isExporting = false
        this.$modal.hide('exportModal')

        if (e.response.data.status == 'fail') {
          this.$toasted.global.general_error({
            message: e.response.data.message
          })
        } else {
          if (e.response.data.message.error == 'creating sales invoices is limited') {
            this.$toasted.global.general_error({
              message: 'Oops, your Moneybird account has reached the allowed sale invoices limit.'
            })
            return false
          }

          if (e.response.data.message.error !== undefined) {
            if (e.response.data.message.error.invoice_sequence_id !== undefined) {
              if (e.response.data.message.error.invoice_sequence_id[0] == 'is already in use') {
                this.$toasted.global.general_error({
                  message: `Invoice ID #${e.response.data.invoice_id}: invoice number already exist in Moneybird.`
                })
                return false
              }
            }
            if (e.response.data.message.error.send_invoices_to_email !== undefined) {
              if (e.response.data.message.error.send_invoices_to_email[0] == 'includes a domain which cannot receive emails') {
                this.$toasted.global.general_error({
                  message: `Your client: ${e.response.data.email_address} email address is not valid.`
                })
                return false
              }
            }
          }

          this.$toasted.global.general_error({
            message: 'No invoices are exported, if problem presist please contact us.'
          })
        }
      }
    },
    showAddPayment(item) {
      this.currency = item.currency

      this.addPayment.invoiceId = item.id
      this.addPayment.date = new Date()
      this.addPayment.amount = this.remainingDueTotal(item.invoice_payments, item.due_total)
      this.addPayment.remainingDueTotal = this.remainingDueTotal(item.invoice_payments, item.due_total)
      this.$modal.show('paymentModal')
    },
    async recordPayment() {
      if (this.addPayment.date == null || this.addPayment.amount <= 0) {
        this.$toasted.global.general_error({
          message: 'Please enter a valid date or amount.'
        })
        return
      }

      let postData = {
        id: this.addPayment.invoiceId,
        date: format(this.addPayment.date, 'YYYY-MM-DD'),
        amount: this.addPayment.amount,
        reference: 'paid'
      }

      const {data} = await this.$api.get('invoice').addPayment(this.addPayment.invoiceId, postData)
      this.addPayment.invoiceId = null
      this.addPayment.id = null
      this.addPayment.date = null
      this.addPayment.amount = 0
      this.addPayment.remainingDueTotal = 0
      this.$modal.hide('paymentModal')

      this.updateFilter()
    },
    taxClasses(items, discount, isTaxPriceIncluded) {
      let subTotal = 0
      let listOfItems = []
      Array.from(items).forEach(child => {
        subTotal = subTotal + (child.price * (child.due_percentage / 100)) * child.quantity
        listOfItems.push(child)
      })

      let discountTotal = 0
      if (discount > 0) {
        discountTotal = subTotal * (discount / 100)
      }

      let discountedTotal = subTotal - discountTotal

      let results = calculateEachTaxClassPrice(listOfItems, subTotal, discountedTotal, isTaxPriceIncluded)
      return results
    },
    taxTotal(items, discount, isTaxPriceIncluded) {
      let subTotal = 0
      let listOfItems = []
      Array.from(items).forEach(child => {
        subTotal = subTotal + (child.price * (child.due_percentage / 100)) * child.quantity
        listOfItems.push(child)
      })

      let discountedTotal = 0
      let discountTotal = 0
      if (discount > 0) {
        discountTotal = subTotal * (discount / 100)
      }

      if (discountTotal > 0) {
        discountedTotal = subTotal - discountTotal
      }

      let results = calculateTaxTotal(listOfItems, subTotal, discountedTotal, isTaxPriceIncluded)
      return results
    },
    viewDiscountedTotal(items, discount, isTaxEnable, isTaxPriceIncluded) {
      let subTotal = 0
      let listOfItems = []
      let discountTotal = 0
      Array.from(items).forEach(child => {
        subTotal = subTotal + (child.price * (child.due_percentage / 100)) * child.quantity
      })
      discountTotal = subTotal * (discount / 100)

      return calculateDiscountTotal(items, discount, discountTotal, isTaxEnable, isTaxPriceIncluded)
    },
    subTotal(items, discount, duePercentage, isTaxEnable, isTaxPriceIncluded) {
      let originalSubTotal = 0
      let listOfItems = []

      Array.from(items).forEach(child => {
        originalSubTotal = originalSubTotal + (child.price * (child.due_percentage / 100)) * child.quantity
        listOfItems.push(child)
      })

      let discountTotal = 0
      if (discount > 0) {
        discountTotal = originalSubTotal * (discount / 100)
      }

      //let discountTotal = calculateDiscountTotal(listOfItems, discount, discountTotal, isTaxEnable, isTaxPriceIncluded)
      let subTotal = ((originalSubTotal - discountTotal) * (duePercentage / 100))

      if (isTaxEnable && isTaxPriceIncluded) {
        let taxTotal = this.taxTotal(listOfItems, discount, isTaxPriceIncluded)
        let tax = parseFloat(taxTotal * (duePercentage / 100)).toFixed(2)
        let value = parseFloat(subTotal) - parseFloat(tax)

        return (Number(value)).toFixed(2)
      }

      return (Number(subTotal)).toFixed(2)
    },
    total(subTotal, duePercentage, taxClasses, isTaxEnable) {
      let taxTotal = 0
      let total = subTotal

      if (isTaxEnable) {
        Object.keys(taxClasses).forEach(taxRate => {
          taxTotal += (taxClasses[taxRate])
        })
        total = parseFloat(total) + parseFloat(taxTotal)
      }

      total = Number(total * (duePercentage / 100))
      return total.toFixed(2)
    },
    remainingDueTotal(payments, dueTotal) {
      let paymentTotal = 0
      payments.forEach(payment => {
        paymentTotal += Number(payment.amount)
      })

      let remaining = Number(dueTotal) - Number(paymentTotal)

      return remaining
    },
    updateFilter() {
      this.$refs.datatable.refresh()
      this.$refs.mobileDatatable.refresh()
    },
    onResize() {
      this.isMobile = window.innerWidth < 769
    },
    async savePdf(item) {
      let taxClasses = this.taxClasses(item.items, item.discount, item.is_tax_price_included)
      let subTotal = this.subTotal(item.items, item.discount, item.due_percentage, item.is_tax_enable, item.is_tax_price_included)
      let total = this.total(subTotal, item.due_percentage, taxClasses, item.is_tax_enable)
      let remainingDueTotal = this.remainingDueTotal(item.invoice_payments, item.due_total)
      let viewDiscountedTotal = this.viewDiscountedTotal(item.items, item.discount, item.is_tax_enable, item.is_tax_price_included)
      var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

      const data = {
        companyId: this.user.company.id,
        invoiceId: item.id,
        remainingDueTotal: remainingDueTotal,
        viewDiscountedTotal: viewDiscountedTotal,
        taxClasses: taxClasses,
        subTotal: subTotal,
        total: total,
        isMobile: this.isMobile,
        isSafari: isSafari,
      }

      this.isPdfLoading = true
      await this.$api.get('pdf').invoice(data).then(response => {

        if (!isSafari && !this.isMobile) {
          let blob = new Blob([response.data], {type: 'application/pdf'})
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `invoice-${item.invoice_number}.pdf`
          link.click()
        } else {
          window.location.href = response.data
        }

        this.isPdfLoading = false
      })
    },
    async getData() {

      this.loading = true
      cancelSource = createCancelSource()

      try {
        const {data} = await this.$api.get('invoice').count(this.user.company.id, {
          cancelToken: cancelSource.token
        })
        this.allCount = data.all
        this.paidCount = data.paid
        this.sentCount = data.sent
        this.draftCount = data.draft
        this.dueCount = data.due
        this.creditCount = data.credit
        this.loading = false

      } catch (e) {
        logException(e)
      }
    },
  },
}
</script>
