<template>
  <span
    class="item-invoice-state capitalize"
    :class="status"
  >{{ status }}</span>
</template>

<script>
export default {
  name: 'StatusBadge',
  props: {
    status: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
